<template>
  <div>
    <v-alert border="bottom" color="pink darken-1" dark>
      403 Forbiden!
    </v-alert>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    console.log('Forbiden!');
  },
}
</script>